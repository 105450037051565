const vsRender =
    `#version 300 es
    in vec3 pos;

    out vec2 rel_coords;

    void main() {
        gl_Position = vec4(pos, 1.0);

        rel_coords = pos.xy * 0.5 + 0.5;
    }
    `;

const fsRender =
    `#version 300 es
    in highp vec2 rel_coords;
    out highp vec4 outColor;

    uniform highp sampler2D tex;
    uniform highp float dt;

    highp vec4 linear_sampling(sampler2D samp, highp vec2 coords) {
        ivec2 size = textureSize(samp, 0);
        highp vec2 absolute_coords = coords * vec2(size) - vec2(0.5);

        ivec2 texel_coords00 = clamp(ivec2(floor(absolute_coords.x), floor(absolute_coords.y)), ivec2(0), size - ivec2(1));
        ivec2 texel_coords01 = clamp(ivec2(floor(absolute_coords.x), ceil(absolute_coords.y)), ivec2(0), size - ivec2(1));
        ivec2 texel_coords10 = clamp(ivec2(ceil(absolute_coords.x), floor(absolute_coords.y)), ivec2(0), size - ivec2(1));
        ivec2 texel_coords11 = clamp(ivec2(ceil(absolute_coords.x), ceil(absolute_coords.y)), ivec2(0), size - ivec2(1));

        highp vec2 absolute_fract = fract(absolute_coords);

        highp vec4 texel00 = texelFetch(samp, texel_coords00, 0);
        highp vec4 texel01 = texelFetch(samp, texel_coords01, 0);
        highp vec4 texel10 = texelFetch(samp, texel_coords10, 0);
        highp vec4 texel11 = texelFetch(samp, texel_coords11, 0);

        return mix(mix(texel00, texel01, absolute_fract.y), mix(texel10, texel11, absolute_fract.y), absolute_fract.x);
    }

    void main() {
        // highp vec4 color = texture(tex, rel_coords);
        highp vec4 color = linear_sampling(tex, rel_coords);

        // highp float red_val = dt - floor(dt);
        // highp vec3 color = vec3(0.2, 0.6, 1.0);

        // color = color_factor.x * color;
        outColor = vec4(color.rgb, 1.0);
    }
    `;

const fsAdvectBoundary =
    `#version 300 es
    in highp vec2 rel_coords;
    out highp vec4 outColor;

    uniform highp sampler2D velocity_sampler;

    uniform highp float timestep;
    uniform highp float dx;
    uniform highp float viscosity;
    uniform ivec2 size;

    highp vec4 linear_sampling(sampler2D samp, highp vec2 coords) {
        ivec2 size = textureSize(samp, 0);
        highp vec2 absolute_coords = coords * vec2(size) - vec2(0.5);

        ivec2 texel_coords00 = clamp(ivec2(floor(absolute_coords.x), floor(absolute_coords.y)), ivec2(0), size - ivec2(1));
        ivec2 texel_coords01 = clamp(ivec2(floor(absolute_coords.x), ceil(absolute_coords.y)), ivec2(0), size - ivec2(1));
        ivec2 texel_coords10 = clamp(ivec2(ceil(absolute_coords.x), floor(absolute_coords.y)), ivec2(0), size - ivec2(1));
        ivec2 texel_coords11 = clamp(ivec2(ceil(absolute_coords.x), ceil(absolute_coords.y)), ivec2(0), size - ivec2(1));

        highp vec2 absolute_fract = fract(absolute_coords);

        highp vec4 texel00 = texelFetch(samp, texel_coords00, 0);
        highp vec4 texel01 = texelFetch(samp, texel_coords01, 0);
        highp vec4 texel10 = texelFetch(samp, texel_coords10, 0);
        highp vec4 texel11 = texelFetch(samp, texel_coords11, 0);

        return mix(mix(texel00, texel01, absolute_fract.y), mix(texel10, texel11, absolute_fract.y), absolute_fract.x);
    }

    highp vec2 advect_boundary(ivec2 coords) {
        highp vec2 d = vec2(1.0) / (vec2(size) - vec2(1.0));

        if (coords.x == 0 && coords.y == 0) {
            return - linear_sampling(velocity_sampler, rel_coords + vec2(d.x, d.y)).xy;
        }
        else if (coords.x == size.x - 1 && coords.y == size.y - 1) {
            return - linear_sampling(velocity_sampler, rel_coords + vec2(-d.x, -d.y)).xy;
        }
        else if (coords.x == size.x - 1 && coords.y == 0) {
            return - linear_sampling(velocity_sampler, rel_coords + vec2(-d.x, d.y)).xy;
        }
        else if (coords.x == 0 && coords.y == size.y - 1) {
            return - linear_sampling(velocity_sampler, rel_coords + vec2(d.x, -d.y)).xy;
        }
        else if (coords.x == 0) {
            return - linear_sampling(velocity_sampler, rel_coords + vec2(d.x, 0.0)).xy;
        }
        else if (coords.x == size.x - 1) {
            return - linear_sampling(velocity_sampler, rel_coords + vec2(-d.x, 0.0)).xy;
        }
        else if (coords.y == 0) {
            return - linear_sampling(velocity_sampler, rel_coords + vec2(0.0, d.y)).xy;
        }
        else if (coords.y == size.y - 1) {
            return - linear_sampling(velocity_sampler, rel_coords + vec2(0.0, -d.y)).xy;
        }

        return linear_sampling(velocity_sampler, rel_coords).xy;
    }

    void main() {
        ivec2 coords = ivec2(floor(rel_coords * vec2(size)));

        outColor = vec4(advect_boundary(coords), 0.0, 0.0);
    }
    `;

const fsFirstColor =
    `#version 300 es
    in highp vec2 rel_coords;
    out highp vec4 outColor;

    uniform highp vec3 background_color;

    void main() {
        // outColor = vec4(0.7071 - length(rel_coords - vec2(0.5, 0.5)), 0.0, 0.0, 0.0);
        outColor = vec4(background_color, 0.0);
    }
    `;

const fsSetColor =
    `#version 300 es
    in highp vec2 rel_coords;
    out highp vec4 outColor;

    uniform highp float timestep;

    uniform highp sampler2D color_sampler;


    uniform highp float viscosity;
    uniform ivec2 click_pos;
    uniform ivec2 size_screen;
    uniform ivec2 size_color;
    uniform highp vec2 force_dir;
    uniform highp vec3 new_color;

    
    highp vec4 linear_sampling(sampler2D samp, highp vec2 coords) {
        ivec2 size = textureSize(samp, 0);
        highp vec2 absolute_coords = coords * vec2(size) - vec2(0.5);

        ivec2 texel_coords00 = clamp(ivec2(floor(absolute_coords.x), floor(absolute_coords.y)), ivec2(0), size - ivec2(1));
        ivec2 texel_coords01 = clamp(ivec2(floor(absolute_coords.x), ceil(absolute_coords.y)), ivec2(0), size - ivec2(1));
        ivec2 texel_coords10 = clamp(ivec2(ceil(absolute_coords.x), floor(absolute_coords.y)), ivec2(0), size - ivec2(1));
        ivec2 texel_coords11 = clamp(ivec2(ceil(absolute_coords.x), ceil(absolute_coords.y)), ivec2(0), size - ivec2(1));

        highp vec2 absolute_fract = fract(absolute_coords);

        highp vec4 texel00 = texelFetch(samp, texel_coords00, 0);
        highp vec4 texel01 = texelFetch(samp, texel_coords01, 0);
        highp vec4 texel10 = texelFetch(samp, texel_coords10, 0);
        highp vec4 texel11 = texelFetch(samp, texel_coords11, 0);

        return mix(mix(texel00, texel01, absolute_fract.y), mix(texel10, texel11, absolute_fract.y), absolute_fract.x);
    }

    void main() {
        highp float dist = distance(rel_coords * vec2(size_screen), vec2(click_pos.x, click_pos.y)) * (20.0 / float(size_screen.y));
        highp float factor = clamp(0.1 * length(force_dir), 0.0, 1.0) * exp(-  pow(dist, 4.0));
        factor = clamp(factor, 0.0, 1.0);
        highp vec3 old_color = linear_sampling(color_sampler, rel_coords).rgb;
        outColor = vec4((1.0 - factor) * old_color + factor * new_color, 0.0);

        // outColor = vec4(new_color, 0.0);
    }
    `

const fsAdvectColor =
    `#version 300 es
    in highp vec2 rel_coords;
    out highp vec4 outColor;

    uniform highp sampler2D color_sampler;
    uniform highp sampler2D velocity_sampler;

    uniform highp float timestep;

    uniform highp float dx_color;
    uniform highp float dy_color;

    uniform highp vec3 background_color;

    uniform ivec2 size;
    uniform ivec2 size_color;

    highp vec2 correctedRelCoords(highp vec2 rel) {
        return (1.5 / vec2(size)) + ((vec2(size) - vec2(3)) / vec2(size)) * rel;
    }

    highp vec4 linear_sampling(sampler2D samp, highp vec2 coords) {
        ivec2 size = textureSize(samp, 0);
        highp vec2 absolute_coords = coords * vec2(size) - vec2(0.5);

        ivec2 texel_coords00 = clamp(ivec2(floor(absolute_coords.x), floor(absolute_coords.y)), ivec2(0), size - ivec2(1));
        ivec2 texel_coords01 = clamp(ivec2(floor(absolute_coords.x), ceil(absolute_coords.y)), ivec2(0), size - ivec2(1));
        ivec2 texel_coords10 = clamp(ivec2(ceil(absolute_coords.x), floor(absolute_coords.y)), ivec2(0), size - ivec2(1));
        ivec2 texel_coords11 = clamp(ivec2(ceil(absolute_coords.x), ceil(absolute_coords.y)), ivec2(0), size - ivec2(1));

        highp vec2 absolute_fract = fract(absolute_coords);

        highp vec4 texel00 = texelFetch(samp, texel_coords00, 0);
        highp vec4 texel01 = texelFetch(samp, texel_coords01, 0);
        highp vec4 texel10 = texelFetch(samp, texel_coords10, 0);
        highp vec4 texel11 = texelFetch(samp, texel_coords11, 0);

        return mix(mix(texel00, texel01, absolute_fract.y), mix(texel10, texel11, absolute_fract.y), absolute_fract.x);
    }


    highp vec3 advect(highp vec2 coords) {
        highp vec2 velocity_value = linear_sampling(velocity_sampler, correctedRelCoords(rel_coords)).xy / vec2(dx_color, dy_color);

        highp vec2 mid_coords = coords - 0.5 * timestep * velocity_value;
        highp vec2 mid_velocity_value = linear_sampling(velocity_sampler, correctedRelCoords(mid_coords / vec2(size_color))).xy / vec2(dx_color, dy_color);

        highp vec2 prev_coords = coords - timestep * mid_velocity_value;
        highp vec2 rel_prev_coords = prev_coords / vec2(size_color);

        clamp(rel_prev_coords, vec2(0.0), vec2(1.0));

        return 0.995 * linear_sampling(color_sampler, rel_prev_coords).rgb + 0.005 * background_color;
    }

    void main() {
        highp vec2 coords = rel_coords * vec2(size_color);

        outColor = vec4(advect(coords), 0.0);
    }
    `;

const fsAdvectVelocity =
    `#version 300 es
    in highp vec2 rel_coords;
    out highp vec4 outColor;

    uniform highp sampler2D velocity_sampler;

    uniform highp float timestep;

    uniform highp float dx_sim;
    uniform highp float dy_sim;

    uniform ivec2 size;

    highp vec4 linear_sampling(sampler2D samp, highp vec2 coords) {
        ivec2 size = textureSize(samp, 0);
        highp vec2 absolute_coords = coords * vec2(size) - vec2(0.5);

        ivec2 texel_coords00 = clamp(ivec2(floor(absolute_coords.x), floor(absolute_coords.y)), ivec2(0), size - ivec2(1));
        ivec2 texel_coords01 = clamp(ivec2(floor(absolute_coords.x), ceil(absolute_coords.y)), ivec2(0), size - ivec2(1));
        ivec2 texel_coords10 = clamp(ivec2(ceil(absolute_coords.x), floor(absolute_coords.y)), ivec2(0), size - ivec2(1));
        ivec2 texel_coords11 = clamp(ivec2(ceil(absolute_coords.x), ceil(absolute_coords.y)), ivec2(0), size - ivec2(1));

        highp vec2 absolute_fract = fract(absolute_coords);

        highp vec4 texel00 = texelFetch(samp, texel_coords00, 0);
        highp vec4 texel01 = texelFetch(samp, texel_coords01, 0);
        highp vec4 texel10 = texelFetch(samp, texel_coords10, 0);
        highp vec4 texel11 = texelFetch(samp, texel_coords11, 0);

        return mix(mix(texel00, texel01, absolute_fract.y), mix(texel10, texel11, absolute_fract.y), absolute_fract.x);
    }


    highp vec2 advect(highp vec2 coords) {
        highp vec2 velocity_value = linear_sampling(velocity_sampler, rel_coords).xy / vec2(dx_sim, dy_sim);

        highp vec2 mid_coords = coords - 0.5 * timestep * velocity_value;
        highp vec2 mid_velocity_value = linear_sampling(velocity_sampler, mid_coords / vec2(size)).xy / vec2(dx_sim, dy_sim);

        highp vec2 prev_coords = coords - timestep * mid_velocity_value;
        highp vec2 rel_prev_coords = prev_coords / vec2(size);

        clamp(rel_prev_coords, vec2(0.0), vec2(1.0));

        return linear_sampling(velocity_sampler, rel_prev_coords).xy;
    }

    void main() {
        highp vec2 coords = rel_coords * vec2(size);

        outColor = vec4(advect(coords), 0.0, 0.0);
    }
    `;

const fsAdvectPressure =
    `#version 300 es
    in highp vec2 rel_coords;
    out highp vec4 outColor;

    uniform highp sampler2D pressure_sampler;
    uniform highp sampler2D velocity_sampler;

    uniform highp float timestep;

    uniform highp float dx_sim;
    uniform highp float dy_sim;

    uniform ivec2 size;

    highp vec4 linear_sampling(sampler2D samp, highp vec2 coords) {
        ivec2 size = textureSize(samp, 0);
        highp vec2 absolute_coords = coords * vec2(size) - vec2(0.5);

        ivec2 texel_coords00 = clamp(ivec2(floor(absolute_coords.x), floor(absolute_coords.y)), ivec2(0), size - ivec2(1));
        ivec2 texel_coords01 = clamp(ivec2(floor(absolute_coords.x), ceil(absolute_coords.y)), ivec2(0), size - ivec2(1));
        ivec2 texel_coords10 = clamp(ivec2(ceil(absolute_coords.x), floor(absolute_coords.y)), ivec2(0), size - ivec2(1));
        ivec2 texel_coords11 = clamp(ivec2(ceil(absolute_coords.x), ceil(absolute_coords.y)), ivec2(0), size - ivec2(1));

        highp vec2 absolute_fract = fract(absolute_coords);

        highp vec4 texel00 = texelFetch(samp, texel_coords00, 0);
        highp vec4 texel01 = texelFetch(samp, texel_coords01, 0);
        highp vec4 texel10 = texelFetch(samp, texel_coords10, 0);
        highp vec4 texel11 = texelFetch(samp, texel_coords11, 0);

        return mix(mix(texel00, texel01, absolute_fract.y), mix(texel10, texel11, absolute_fract.y), absolute_fract.x);
    }


    highp float advect(highp vec2 coords) {
        highp vec2 velocity_value = linear_sampling(velocity_sampler, rel_coords).xy / vec2(dx_sim, dy_sim);

        highp vec2 mid_coords = coords - 0.5 * timestep * velocity_value;
        highp vec2 mid_velocity_value = linear_sampling(velocity_sampler, mid_coords / vec2(size)).xy / vec2(dx_sim, dy_sim);

        highp vec2 prev_coords = coords - timestep * mid_velocity_value;
        highp vec2 rel_prev_coords = prev_coords / vec2(size);

        clamp(rel_prev_coords, vec2(0.0), vec2(1.0));

        return linear_sampling(pressure_sampler, rel_prev_coords).x;
    }

    void main() {
        highp vec2 coords = rel_coords * vec2(size);

        outColor = vec4(advect(coords), 0.0, 0.0, 0.0);
    }
    `;

const fsDiffuse =
    `#version 300 es
    in highp vec2 rel_coords;
    out highp vec4 outColor;

    uniform highp sampler2D velocity_sampler;

    uniform highp float timestep;
    uniform highp float dx_sim;
    uniform highp float dy_sim;
    uniform highp float viscosity;
    uniform ivec2 size;

    highp vec4 linear_sampling(sampler2D samp, highp vec2 coords) {
        ivec2 size = textureSize(samp, 0);
        highp vec2 absolute_coords = coords * vec2(size) - vec2(0.5);

        ivec2 texel_coords00 = clamp(ivec2(floor(absolute_coords.x), floor(absolute_coords.y)), ivec2(0), size - ivec2(1));
        ivec2 texel_coords01 = clamp(ivec2(floor(absolute_coords.x), ceil(absolute_coords.y)), ivec2(0), size - ivec2(1));
        ivec2 texel_coords10 = clamp(ivec2(ceil(absolute_coords.x), floor(absolute_coords.y)), ivec2(0), size - ivec2(1));
        ivec2 texel_coords11 = clamp(ivec2(ceil(absolute_coords.x), ceil(absolute_coords.y)), ivec2(0), size - ivec2(1));

        highp vec2 absolute_fract = fract(absolute_coords);

        highp vec4 texel00 = texelFetch(samp, texel_coords00, 0);
        highp vec4 texel01 = texelFetch(samp, texel_coords01, 0);
        highp vec4 texel10 = texelFetch(samp, texel_coords10, 0);
        highp vec4 texel11 = texelFetch(samp, texel_coords11, 0);

        return mix(mix(texel00, texel01, absolute_fract.y), mix(texel10, texel11, absolute_fract.y), absolute_fract.x);
    }


    highp vec2 velocity_diffuse(ivec2 coords) {
        highp vec2 d = (vec2(1.0) / (vec2(size) - vec2(1))) / vec2(dx_sim / dy_sim, 1.0);
        highp float alpha = dx_sim * dx_sim / (viscosity * timestep);
        highp float r_beta = 1.0 / (4.0 + alpha);

        highp vec2 diffused_velocity;

        if (rel_coords.x > 0.0 && rel_coords.x < 1.0 && rel_coords.y > 0.0 && rel_coords.y < 1.0) {
            highp vec2 xL = linear_sampling(velocity_sampler, rel_coords - vec2(d.x, 0)).xy;
            highp vec2 xR = linear_sampling(velocity_sampler, rel_coords + vec2(d.x, 0)).xy;
            highp vec2 xB = linear_sampling(velocity_sampler, rel_coords - vec2(0, d.y)).xy;
            highp vec2 xT = linear_sampling(velocity_sampler, rel_coords + vec2(0, d.y)).xy;

            highp vec2 b = linear_sampling(velocity_sampler, rel_coords).xy;

            diffused_velocity = (xL + xR + xB + xT + alpha * b) * r_beta;
        } else {
            diffused_velocity = linear_sampling(velocity_sampler, rel_coords).xy;
        }

        return diffused_velocity;
    }

    void main() {
        ivec2 coords = ivec2(floor(rel_coords * vec2(size)));

        outColor = vec4(velocity_diffuse(coords), 0.0, 0.0);
    }
    `

const fsApplyForce =
    `#version 300 es
    in highp vec2 rel_coords;
    out highp vec4 outColor;

    uniform highp sampler2D velocity_sampler;

    uniform highp float timestep;
    uniform ivec2 size;
    uniform ivec2 size_screen;
    uniform ivec2 click_pos;
    uniform highp vec2 force_dir;

    uniform highp float force_factor;
    uniform highp float distance_factor;

    highp vec4 linear_sampling(sampler2D samp, highp vec2 coords) {
        ivec2 size = textureSize(samp, 0);
        highp vec2 absolute_coords = coords * vec2(size) - vec2(0.5);

        ivec2 texel_coords00 = clamp(ivec2(floor(absolute_coords.x), floor(absolute_coords.y)), ivec2(0), size - ivec2(1));
        ivec2 texel_coords01 = clamp(ivec2(floor(absolute_coords.x), ceil(absolute_coords.y)), ivec2(0), size - ivec2(1));
        ivec2 texel_coords10 = clamp(ivec2(ceil(absolute_coords.x), floor(absolute_coords.y)), ivec2(0), size - ivec2(1));
        ivec2 texel_coords11 = clamp(ivec2(ceil(absolute_coords.x), ceil(absolute_coords.y)), ivec2(0), size - ivec2(1));

        highp vec2 absolute_fract = fract(absolute_coords);

        highp vec4 texel00 = texelFetch(samp, texel_coords00, 0);
        highp vec4 texel01 = texelFetch(samp, texel_coords01, 0);
        highp vec4 texel10 = texelFetch(samp, texel_coords10, 0);
        highp vec4 texel11 = texelFetch(samp, texel_coords11, 0);

        return mix(mix(texel00, texel01, absolute_fract.y), mix(texel10, texel11, absolute_fract.y), absolute_fract.x);
    }


    highp vec2 applyForce(ivec2 coords) {
        if (coords.x > 0 && coords.x < size.x - 1 && coords.y > 0 && coords.y < size.x - 1) {
            highp float dist = distance(vec2(rel_coords * vec2(size_screen)), vec2(click_pos));
            highp vec2 v_xy = force_factor * force_dir * exp(- (dist * dist) / distance_factor);
            // highp vec2 v_xy = 10.0 * force_dir * exp(- (dist * dist) / 512.0);
            
            // highp vec2 v_xy = 20.0 * vec2(0.0, -1.0) * exp(- distance(vec2(coords), vec2(click_pos)) * distance(vec2(coords), vec2(click_pos)) / 5.0);
            // highp vec2 dir = vec2(click_pos) - vec2(coords);
            // highp vec2 v_xy = length(dir) < 10.0 ? normalize(dir) * 40.0 : vec2(0.0);
            // highp vec2 v_xy = vec2(0.0, -50.0);
            return v_xy + linear_sampling(velocity_sampler, rel_coords).xy;
        }

        return vec2(0.0, 0.0);
    }

    void main() {
        ivec2 coords = ivec2(floor(rel_coords * vec2(size)));

        outColor = vec4(applyForce(coords), 0.0, 0.0);
    }
    `

const fsDivergence =
    `#version 300 es
    in highp vec2 rel_coords;
    out highp vec4 outColor;

    uniform highp sampler2D velocity_sampler;

    uniform highp float timestep;
    uniform highp float dx_sim;
    uniform highp float dy_sim;
    uniform highp float viscosity;
    uniform ivec2 size;

    highp vec4 linear_sampling(sampler2D samp, highp vec2 coords) {
        ivec2 size = textureSize(samp, 0);
        highp vec2 absolute_coords = coords * vec2(size) - vec2(0.5);

        ivec2 texel_coords00 = clamp(ivec2(floor(absolute_coords.x), floor(absolute_coords.y)), ivec2(0), size - ivec2(1));
        ivec2 texel_coords01 = clamp(ivec2(floor(absolute_coords.x), ceil(absolute_coords.y)), ivec2(0), size - ivec2(1));
        ivec2 texel_coords10 = clamp(ivec2(ceil(absolute_coords.x), floor(absolute_coords.y)), ivec2(0), size - ivec2(1));
        ivec2 texel_coords11 = clamp(ivec2(ceil(absolute_coords.x), ceil(absolute_coords.y)), ivec2(0), size - ivec2(1));

        highp vec2 absolute_fract = fract(absolute_coords);

        highp vec4 texel00 = texelFetch(samp, texel_coords00, 0);
        highp vec4 texel01 = texelFetch(samp, texel_coords01, 0);
        highp vec4 texel10 = texelFetch(samp, texel_coords10, 0);
        highp vec4 texel11 = texelFetch(samp, texel_coords11, 0);

        return mix(mix(texel00, texel01, absolute_fract.y), mix(texel10, texel11, absolute_fract.y), absolute_fract.x);
    }

    highp float divergence_calc(ivec2 coords) {
        highp vec2 d = (vec2(1.0) / (vec2(size) - vec2(1))) / vec2(dx_sim / dy_sim, 1.0);
        highp float halfrdx = 0.5 / dx_sim;

        if (rel_coords.x > 0.0 && rel_coords.x < 1.0 && rel_coords.y > 0.0 && rel_coords.y < 1.0) {
            highp vec2 xL = linear_sampling(velocity_sampler, rel_coords - vec2(d.x, 0)).xy;
            highp vec2 xR = linear_sampling(velocity_sampler, rel_coords + vec2(d.x, 0)).xy;
            highp vec2 xB = linear_sampling(velocity_sampler, rel_coords - vec2(0, d.y)).xy;
            highp vec2 xT = linear_sampling(velocity_sampler, rel_coords + vec2(0, d.y)).xy;

            return halfrdx * ((xR.x - xL.x) + (xT.y - xB.y));
        }

        return 0.0;
    }

    void main() {
        ivec2 coords = ivec2(floor(rel_coords * vec2(size)));

        outColor = vec4(divergence_calc(coords), 0.0, 0.0, 0.0);
    }`;

const fsPressureBoundary =
    `#version 300 es
    in highp vec2 rel_coords;
    out highp vec4 outColor;

    uniform highp sampler2D pressure_sampler;

    uniform highp float timestep;
    uniform ivec2 size;

    highp vec4 linear_sampling(sampler2D samp, highp vec2 coords) {
        ivec2 size = textureSize(samp, 0);
        highp vec2 absolute_coords = coords * vec2(size) - vec2(0.5);

        ivec2 texel_coords00 = clamp(ivec2(floor(absolute_coords.x), floor(absolute_coords.y)), ivec2(0), size - ivec2(1));
        ivec2 texel_coords01 = clamp(ivec2(floor(absolute_coords.x), ceil(absolute_coords.y)), ivec2(0), size - ivec2(1));
        ivec2 texel_coords10 = clamp(ivec2(ceil(absolute_coords.x), floor(absolute_coords.y)), ivec2(0), size - ivec2(1));
        ivec2 texel_coords11 = clamp(ivec2(ceil(absolute_coords.x), ceil(absolute_coords.y)), ivec2(0), size - ivec2(1));

        highp vec2 absolute_fract = fract(absolute_coords);

        highp vec4 texel00 = texelFetch(samp, texel_coords00, 0);
        highp vec4 texel01 = texelFetch(samp, texel_coords01, 0);
        highp vec4 texel10 = texelFetch(samp, texel_coords10, 0);
        highp vec4 texel11 = texelFetch(samp, texel_coords11, 0);

        return mix(mix(texel00, texel01, absolute_fract.y), mix(texel10, texel11, absolute_fract.y), absolute_fract.x);
    }


    highp float pressure_boundary(ivec2 coords) {
        highp vec2 d = vec2(1.0) / (vec2(size) - vec2(1.0));

        if (coords.x == 0 && coords.y == 0) {
            return linear_sampling(pressure_sampler, rel_coords + vec2(d.x, d.y)).x;
        }
        else if (coords.x == size.x - 1 && coords.y == size.y - 1) {
            return linear_sampling(pressure_sampler, rel_coords + vec2(-d.x, -d.y)).x;
        }
        else if (coords.x == size.x - 1 && coords.y == 0) {
            return linear_sampling(pressure_sampler, rel_coords + vec2(-d.x, d.y)).x;
        }
        else if (coords.x == 0 && coords.y == size.y - 1) {
            return linear_sampling(pressure_sampler, rel_coords + vec2(d.x, -d.y)).x;
        }
        else if (coords.x == 0) {
            return linear_sampling(pressure_sampler, rel_coords + vec2(d.x, 0.0)).x;
        }
        else if (coords.x == size.x - 1) {
            return linear_sampling(pressure_sampler, rel_coords + vec2(-d.x, 0.0)).x;
        }
        else if (coords.y == 0) {
            return linear_sampling(pressure_sampler, rel_coords + vec2(0.0, d.y)).x;
        }
        else if (coords.y == size.y - 1) {
            return linear_sampling(pressure_sampler, rel_coords + vec2(0.0, -d.y)).x;
        }

        return linear_sampling(pressure_sampler, rel_coords).x;
    }

    void main() {
        ivec2 coords = ivec2(floor(rel_coords * vec2(size)));

        outColor = vec4(pressure_boundary(coords), 0.0, 0.0, 0.0);
    }`;


const fsPressureSolve =
    `#version 300 es
    in highp vec2 rel_coords;
    out highp vec4 outColor;

    uniform highp sampler2D pressure_sampler;
    uniform highp sampler2D divergence_sampler;

    uniform highp float timestep;
    uniform highp float dx_sim;
    uniform highp float dy_sim;
    uniform ivec2 size;

    highp vec4 linear_sampling(sampler2D samp, highp vec2 coords) {
        ivec2 size = textureSize(samp, 0);
        highp vec2 absolute_coords = coords * vec2(size) - vec2(0.5);

        ivec2 texel_coords00 = clamp(ivec2(floor(absolute_coords.x), floor(absolute_coords.y)), ivec2(0), size - ivec2(1));
        ivec2 texel_coords01 = clamp(ivec2(floor(absolute_coords.x), ceil(absolute_coords.y)), ivec2(0), size - ivec2(1));
        ivec2 texel_coords10 = clamp(ivec2(ceil(absolute_coords.x), floor(absolute_coords.y)), ivec2(0), size - ivec2(1));
        ivec2 texel_coords11 = clamp(ivec2(ceil(absolute_coords.x), ceil(absolute_coords.y)), ivec2(0), size - ivec2(1));

        highp vec2 absolute_fract = fract(absolute_coords);

        highp vec4 texel00 = texelFetch(samp, texel_coords00, 0);
        highp vec4 texel01 = texelFetch(samp, texel_coords01, 0);
        highp vec4 texel10 = texelFetch(samp, texel_coords10, 0);
        highp vec4 texel11 = texelFetch(samp, texel_coords11, 0);

        return mix(mix(texel00, texel01, absolute_fract.y), mix(texel10, texel11, absolute_fract.y), absolute_fract.x);
    }


    highp float pressure_solve(ivec2 coords) {
        highp vec2 d = (vec2(1.0) / (vec2(size) - vec2(1))) / vec2(dx_sim / dy_sim, 1.0);

        highp float alpha = -dx_sim * dx_sim;
        highp float r_beta = 1.0 / 4.0;

        highp float pressure_val;

        if (rel_coords.x >= 0.0 && rel_coords.x <= 1.0 && rel_coords.y >= 0.0 && rel_coords.y <= 1.0) {
            highp float pL = texture(pressure_sampler, rel_coords - vec2(d.x, 0)).x;
            highp float pR = texture(pressure_sampler, rel_coords + vec2(d.x, 0)).x;
            highp float pB = texture(pressure_sampler, rel_coords - vec2(0, d.y)).x;
            highp float pT = texture(pressure_sampler, rel_coords + vec2(0, d.y)).x;

            highp float b = texture(divergence_sampler, rel_coords).x;

            pressure_val = (pL + pR + pB + pT + alpha * b) * r_beta;
        } else {
            pressure_val = texture(pressure_sampler, rel_coords).x;
        }

        return pressure_val;
    }

    void main() {
        ivec2 coords = ivec2(floor(rel_coords * vec2(size)));

        outColor = vec4(pressure_solve(coords), 0.0, 0.0, 0.0);
    }`;

const fsGradientSub =
    `#version 300 es
    in highp vec2 rel_coords;
    out highp vec4 outColor;

    uniform highp sampler2D velocity_sampler;
    uniform highp sampler2D pressure_sampler;

    uniform highp float timestep;
    uniform highp float dx_sim;
    uniform highp float dy_sim;
    uniform highp float viscosity;
    uniform ivec2 size;

    highp vec4 linear_sampling(sampler2D samp, highp vec2 coords) {
        ivec2 size = textureSize(samp, 0);
        highp vec2 absolute_coords = coords * vec2(size) - vec2(0.5);

        ivec2 texel_coords00 = clamp(ivec2(floor(absolute_coords.x), floor(absolute_coords.y)), ivec2(0), size - ivec2(1));
        ivec2 texel_coords01 = clamp(ivec2(floor(absolute_coords.x), ceil(absolute_coords.y)), ivec2(0), size - ivec2(1));
        ivec2 texel_coords10 = clamp(ivec2(ceil(absolute_coords.x), floor(absolute_coords.y)), ivec2(0), size - ivec2(1));
        ivec2 texel_coords11 = clamp(ivec2(ceil(absolute_coords.x), ceil(absolute_coords.y)), ivec2(0), size - ivec2(1));

        highp vec2 absolute_fract = fract(absolute_coords);

        highp vec4 texel00 = texelFetch(samp, texel_coords00, 0);
        highp vec4 texel01 = texelFetch(samp, texel_coords01, 0);
        highp vec4 texel10 = texelFetch(samp, texel_coords10, 0);
        highp vec4 texel11 = texelFetch(samp, texel_coords11, 0);

        return mix(mix(texel00, texel01, absolute_fract.y), mix(texel10, texel11, absolute_fract.y), absolute_fract.x);
    }


    highp vec2 gradient_sub(ivec2 coords) {
        highp vec2 d = (vec2(1.0) / (vec2(size) - vec2(1))) / vec2(dx_sim / dy_sim, 1.0);
        
        highp float halfrdx = 0.5 / dx_sim;

        if (rel_coords.x > 0.0 && rel_coords.x < 1.0 && rel_coords.y > 0.0 && rel_coords.y < 1.0) {
            highp float pL = linear_sampling(pressure_sampler, rel_coords - vec2(d.x, 0)).x;
            highp float pR = linear_sampling(pressure_sampler, rel_coords + vec2(d.x, 0)).x;
            highp float pB = linear_sampling(pressure_sampler, rel_coords - vec2(0, d.y)).x;
            highp float pT = linear_sampling(pressure_sampler, rel_coords + vec2(0, d.y)).x;

            highp vec2 current_velocity = linear_sampling(velocity_sampler, rel_coords).xy;
            return (current_velocity - halfrdx * vec2(pR - pL, pT - pB));
        } else {
            return linear_sampling(velocity_sampler, rel_coords).xy;
        }
    }


    void main() {
        ivec2 coords = ivec2(floor(rel_coords * vec2(size)));

        outColor = vec4(gradient_sub(coords), 0.0, 0.0);
    }
    `;

export {
    vsRender, fsRender,
    fsAdvectBoundary,
    fsFirstColor,
    fsSetColor,
    fsAdvectColor,
    fsAdvectVelocity,
    fsAdvectPressure,
    fsDiffuse,
    fsApplyForce,
    fsDivergence,
    fsPressureBoundary,
    fsPressureSolve,
    fsGradientSub
}
