import React from 'react';
import Navbar from '../navbar/navbar';
import Footer from '../footer/footer';
import ResumeDescription from '../resume_description/ResumeDescription';

import fr_flag from './fr-flag.png';
import jp_flag from './jp-flag.png';
import gb_flag from './gb-flag.png';

import './Resume.css';

class Resume extends React.Component {
  componentDidMount() {
    document.title = 'CJ - Resume'
  }

  render() {
    return (
      <div className='Resume bg-gray-100 font-sans min-h-screen'>
        <Navbar selected="resume" />

        <div className="container w-full md:max-w-6xl mx-auto px-10 my-20">
          <div className='grid md:grid-cols-8 mb-4'>
            <div className='md:col-start-1 md:col-span-3'>
              <div className='text-center'>
                <div className='resume-avatar'>
                  <img alt="" src={"https://avatars.githubusercontent.com/u/9611659?v=4"} />
                </div>
                <h2 className="text-xl text-gray-800 mt-4">Clément Jacquet</h2>
                <span className='text-sm text-gray-700'>Osaka, Japan</span><br />
                <p className='text-sm text-gray-900 mt-2 max-w-xs mx-auto'>
                  French system engineer specialized in graphics and software design. Developer for nearly 10 years, I enjoy computer graphics and creating high-performance software, mostly in C++.
                </p>
                <div className='mt-4 mb-4 max-w-xs mx-auto'>
                  <hr className="border-b-1 border-gray-300" />
                </div>
                <div className='text-left max-w-xs mx-auto mt-3'>
                  <span className='text-sm'><img alt="" className='resume-flag' src={fr_flag}></img> Native speaker</span><br />
                  <span className='text-sm'><img alt="" className='resume-flag' src={gb_flag}></img> Professional working proficiency</span><br />
                  <span className='text-sm'><img alt="" className='resume-flag' src={jp_flag}></img> Limited working proficiency (JLPT N2)</span>
                </div>
                <div className='mt-4 mb-4 max-w-xs mx-auto'>
                  <hr className="border-b-1 border-gray-300" />
                </div>
                <div className='pb-6'>
                  <div>
                    <span className=''>Very familiar</span>
                  </div>
                  <div>
                    <span className='text-sm px-2 text-gray-700'>C++</span>
                    <span className='text-sm px-2 text-gray-700'>OpenGL</span>
                    <span className='text-sm px-2 text-gray-700'>Python</span>
                    <span className='text-sm px-2 text-gray-700'>Javascript</span>
                  </div>
                  <div className='mt-2'>
                    <span>From time to time</span>
                  </div>
                  <div>
                    <span className='text-sm px-2 text-gray-700'>C</span>
                    <span className='text-sm px-2 text-gray-700'>Vulkan</span>
                    <span className='text-sm px-2 text-gray-700'>C#</span>
                    <span className='text-sm px-2 text-gray-700'>Java</span>
                    <span className='text-sm px-2 text-gray-700'>Rust</span>
                    <span className='text-sm px-2 text-gray-700'>Ruby</span><br />
                    <span className='text-sm px-2 text-gray-700'>Typescript</span>
                    <span className='text-sm px-2 text-gray-700'>AngularJS</span>
                    <span className='text-sm px-2 text-gray-700'>ReactJS</span>
                  </div>
                  <div className='mt-2'>
                    <span>At rare occasions</span>
                  </div>
                  <div>
                    <span className='text-sm px-2 text-gray-700'>Unity</span>
                    <span className='text-sm px-2 text-gray-700'>PHP</span>
                    <span className='text-sm px-2 text-gray-700'>Java</span>
                    <span className='text-sm px-2 text-gray-700'>Rust</span>
                    <span className='text-sm px-2 text-gray-700'>Ruby</span>
                  </div>
                </div>

              </div>

            </div>
            <div className='md:col-span-5 md:col-start-4'>
              <div className='resume-box md:border-l-2 border-gray-300 mb-8 md:px-6'>
                <h2>Experience</h2>
                <ul>
                  <li>
                    <h3 className='font-medium'>Lead System Engineer at <a target='_blank' rel="noreferrer" className="text-sky-500 no-underline hover:underline" href='http://rokken.tech/'>Rokken Inc.</a></h3>
                    <span className='text-sm text-gray-600'>January 2019~Now, Osaka, Japan</span>
                    <ResumeDescription>
                      <p className='text-sm text-gray-700 mt-1'>Development of a 3D real-time medical software for assistance during surgeries, from prototyping to the release in hospitals
                        of a production-ready version. My responsibilities in this project covered the conception of the software architecture and the development of the 3D real-time renderer.
                      </p>
                      <p className='text-sm text-gray-700 mt-2'>
                        To meet performance requirements, we developed a multithreaded and memory-efficient application with C++, alongside accelerated computing with OpenGL compute and Cuda.
                      </p>
                      <p className='text-sm text-gray-700 mt-2'>
                        Our 3D renderer, written with C++ and OpenGL, is tailored for the visualization of 3D volumes that are frequently updated. It relies on acceleration
                        structures (octrees) to speedup the volume ray traversal with fast updates, and supports volume clipping and anti-aliasing. This renderer is planned to be released
                        as an open source repository soon.
                      </p>
                      <p className='text-sm text-gray-700 mt-2 mb-1'>
                        The volume is built from a signal of 2D intra-vascular ultrasound images of the heart (ICE). These images are segmented with a deep-learning segmentation model,
                        then stacked into a volume that is finally post-processed to construct a smooth and understandable representation of the heart.
                      </p>
                    </ResumeDescription>
                  </li>

                  <li><h3 className='font-medium'>Master in Computer Science</h3> <span className='text-sm text-gray-600'>2015~2018, ENSEIRB-MATMECA Bordeaux INP, France</span>
                    <ul>
                      <li>Minor in Machine Learning (as an exchange student)<br /> <span className='text-sm text-gray-600'>2017, Keio University, Japan</span></li>
                      <li>Internship at the research laboratory "Intelligent Media Processing Group" <br /><span className='text-sm text-gray-600'>2018, Osaka Metropolitan University, Japan</span></li>
                    </ul>
                  </li>
                  <li><h3 className='font-medium'>2 years technical degree (french: "DUT") in Computer Science</h3> <span className='text-sm text-gray-600'>2013~2015, University of Bordeaux, France</span>
                    <ul>
                      <li>Internship at KDE laboratory <br /> <span className='text-sm text-gray-600'>2015, Tsukuba University, Japan</span></li>
                    </ul>
                  </li>


                </ul>
              </div>
              <div className='resume-box md:border-l-2 border-gray-300 mb-8 md:px-6'>
                <h2>Publications</h2>
                <ul>
                  <li>
                    <h3 className='font-medium'>Papers</h3>
                    <ResumeDescription>
                      <p className='text-sm text-gray-700 mb-1 mt-1'>
                        During my internship at the Intelligent Media Processing Group, I developed a web application with AngularJS which, combined with a Tobii eye tracker,
                        can detect the words an user is reading.
                      </p>
                      <p className='text-sm text-gray-700 mb-1 mt-1'>
                        These words are then used to recommend texts that could help them improve their vocabulary. We published two papers about this application, provided below.
                      </p>
                    </ResumeDescription>
                    <ul>
                      <li className='mt-2'>
                        <h4><a className="text-gray-800 font-medium no-underline hover:underline" href='https://ieeexplore.ieee.org/abstract/document/8395180' rel="noreferrer" target="_blank">Vocabulometer: A Web Platform for Document and Reader Mutual Analysis</a></h4>
                        <p className='text-sm text-gray-600'>Olivier Augereau, Clément Jacquet, Koichi Kise and Nicholas Journet</p>
                        <p className='text-sm text-gray-600 leading-tight'>2018 13th IAPR International Workshop on Document Analysis Systems (DAS), 2018
                        </p>

                      </li>
                      <li className='mt-2'>
                        <h4><a className="text-gray-800 font-medium no-underline hover:underline" href='https://dl.acm.org/doi/abs/10.1145/3267305.3267614' rel="noreferrer" target="_blank">Vocabulometer, a Web Platform for Ubiquitous Language Learning</a></h4>
                        <p className='text-sm text-gray-600 leading-tight'>Clément Jacquet, Olivier Augereau, Nicholas Journet, and Koichi Kise</p>
                        <p className='text-sm text-gray-600 leading-tight'>In Proceedings of the 2018 ACM International Joint Conference and 2018 International Symposium on Pervasive and Ubiquitous Computing and Wearable Computers (UbiComp '18)
                        </p>
                      </li>
                    </ul>
                  </li>
                  <li className='mt-3'>
                    <h3 className='font-medium'>Patents (16).
                      <span className='text-sm font-normal text-gray-700'> See them all <a className="no-underline hover:underline font-medium" rel="noreferrer" target="_blank" href='https://patents.google.com/?inventor=%E3%82%AF%E3%83%AC%E3%83%A2%E3%83%B3+%E3%82%B8%E3%83%A3%E3%82%B1'>here</a>.</span>
                    </h3>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>


        <Footer />
      </div>
    );
  }
}

export default Resume;