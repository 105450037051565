import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Main from './main/Main';
import Blog from './blog/Blog';
import Resume from './resume/Resume';
import Projects from './projects/Projects';
import FluidSimulation from './fluid_simulation/FluidSimulation'
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, RouterProvider } from "react-router-dom";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Main />,
  },
  {
    path: "/resume",
    element: <Resume />,
  },
  {
    path: "/projects",
    element: <Projects />,
  },
  {
    path: "/blog",
    element: <Blog />,
  },
  {
    path: '/projects/fluid-simulation',
    element: <FluidSimulation />
  }
]);

class SafariFix extends React.Component {
  componentDidMount() {
    window.scrollBy(0, 1)
    window.scrollBy(0, -1)
  }
  
  render() {
    return <></>
  }
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <div>

    <SafariFix />

    <RouterProvider router={router} />
  </div>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
