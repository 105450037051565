const getFloatTypeBuilder = (gl) => {
    const vs =
        `#version 300 es
            in vec4 a_position;
    
            void main() {
                gl_Position = a_position;
            }`

    const fs =
        `#version 300 es
            precision highp float;
            uniform vec4 u_color;
            uniform sampler2D u_texture;

            out vec4 outColor;
            
            void main() {
                float val = texture(u_texture, vec2(0.5, 0.5)).r;
                // float val01 = texelFetch(u_texture, ivec2(0, 0), 0).r;
                // float val02 = texelFetch(u_texture, ivec2(1, 0), 0).r;
                // float val = (val01 + val02) / 2.0;
                
                outColor = vec4(vec3(val), 1.0) * u_color;
            }
        `

    //
    // Initialize a shader program, so WebGL knows how to draw our data
    //
    function initShaderProgram(gl, vsSource, fsSource) {
        const vertexShader = loadShader(gl, gl.VERTEX_SHADER, vsSource);
        const fragmentShader = loadShader(gl, gl.FRAGMENT_SHADER, fsSource);

        // Create the shader program

        const shaderProgram = gl.createProgram();
        gl.attachShader(shaderProgram, vertexShader);
        gl.attachShader(shaderProgram, fragmentShader);
        gl.linkProgram(shaderProgram);

        // If creating the shader program failed, alert

        if (!gl.getProgramParameter(shaderProgram, gl.LINK_STATUS)) {
            alert('Unable to initialize the shader program: ' + gl.getProgramInfoLog(shaderProgram));
            return null;
        }

        return shaderProgram;
    }

    //
    // creates a shader of the given type, uploads the source and
    // compiles it.
    //
    function loadShader(gl, type, source) {
        const shader = gl.createShader(type);

        // Send the source to the shader object

        gl.shaderSource(shader, source);

        // Compile the shader program

        gl.compileShader(shader);

        // See if it compiled successfully

        if (!gl.getShaderParameter(shader, gl.COMPILE_STATUS)) {
            alert('An error occurred compiling the shaders: ' + gl.getShaderInfoLog(shader));
            gl.deleteShader(shader);
            return null;
        }

        return shader;
    }

    // setup GLSL program
    var program = initShaderProgram(gl, vs, fs);
    gl.useProgram(program);

    // look up where the vertex data needs to go.
    var positionLocation = gl.getAttribLocation(program, "a_position");
    var colorLoc = gl.getUniformLocation(program, "u_color");
    var textureLoc = gl.getUniformLocation(program, "u_texture");

    // provide texture coordinates for the rectangle.
    var positionBuffer = gl.createBuffer();
    gl.bindBuffer(gl.ARRAY_BUFFER, positionBuffer);
    gl.bufferData(gl.ARRAY_BUFFER, new Float32Array([
        -1.0, -1.0,
        1.0, -1.0,
        -1.0, 1.0,
        -1.0, 1.0,
        1.0, -1.0,
        1.0, 1.0]), gl.STATIC_DRAW);
    gl.enableVertexAttribArray(positionLocation);
    gl.vertexAttribPointer(positionLocation, 2, gl.FLOAT, false, 0, 0);

    gl.useProgram(null);
    gl.bindBuffer(gl.ARRAY_BUFFER, null);

    var whiteTex = gl.createTexture();
    gl.bindTexture(gl.TEXTURE_2D, whiteTex);
    gl.texParameterf(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR);
    gl.texParameterf(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.LINEAR);
    gl.texImage2D(gl.TEXTURE_2D, 0, gl.R32F, 2, 1, 0, gl.RED, gl.FLOAT,
        new Float32Array([1.0, 0.0]));

    gl.bindTexture(gl.TEXTURE_2D, null);

    var tex = gl.createTexture();
    gl.bindTexture(gl.TEXTURE_2D, tex);
    gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA32F, 1, 1, 0, gl.RGBA, gl.FLOAT, null);
    gl.texParameterf(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR);
    gl.texParameterf(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.LINEAR);

    var fb = gl.createFramebuffer();
    gl.bindFramebuffer(gl.FRAMEBUFFER, fb);
    gl.framebufferTexture2D(gl.FRAMEBUFFER, gl.COLOR_ATTACHMENT0, gl.TEXTURE_2D, tex, 0);
    var status = gl.checkFramebufferStatus(gl.FRAMEBUFFER);
    gl.bindFramebuffer(gl.FRAMEBUFFER, null);


    if (status !== gl.FRAMEBUFFER_COMPLETE) {
        console.error("INCOMPLETE")
        return false;
    }
    gl.useProgram(program);
    gl.bindBuffer(gl.ARRAY_BUFFER, positionBuffer);

    gl.enableVertexAttribArray(positionLocation);
    gl.vertexAttribPointer(positionLocation, 2, gl.FLOAT, false, 0, 0);

    // Draw the rectangle.
    gl.bindFramebuffer(gl.FRAMEBUFFER, fb);
    gl.viewport(0, 0, 1, 1);
    gl.clear(gl.COLOR_BUFFER_BIT);

    gl.bindTexture(gl.TEXTURE_2D, whiteTex);

    const color = [1.0, 1.0, 0.5, 1]

    gl.uniform4fv(colorLoc, color);
    gl.uniform1i(textureLoc, 0);
    gl.drawArrays(gl.TRIANGLES, 0, 6);

    var pixel = new Float32Array(4);
    gl.readPixels(0, 0, 1, 1, gl.RGBA, gl.FLOAT, pixel);

    // gl.bindFramebuffer(gl.FRAMEBUFFER, null);


    gl.bindTexture(gl.TEXTURE_2D, null);
    gl.useProgram(null);
    gl.bindBuffer(gl.ARRAY_BUFFER, null);

    const supports_linear = 0.499 < pixel[0] && pixel[0] < 0.501;

    return supports_linear;
}

function loadWebGL1(gl) {
    const exts = {
        color_float: gl.getExtension('EXT_color_buffer_float'),
        texture_float: gl.getExtension('OES_texture_float'),
        texture_float_linear: gl.getExtension('OES_texture_float_linear'),
        texture_half_float: gl.getExtension('OES_texture_half_float'),
        texture_half_float_linear: gl.getExtension('OES_texture_half_float_linear')
    };

    const supports_linear = getFloatTypeBuilder(gl, exts, gl.RGBA, gl.RGBA32F, gl.FLOAT, exts.texture_half_float.HALF_FLOAT_OES)();
    const float_type = gl.FLOAT;

    return {
        version: 1,
        exts: exts,
        gl: gl,
        float32Support: (float_type === gl.FLOAT),
        float16Support: (float_type === gl.FLOAT || float_type === exts.texture_half_float.HALF_FLOAT_OES),
        format: {
            r: gl.RGBA,
            rg: gl.RGBA,
            rgb: gl.RGBA,
            rgba: gl.RGBA
        },
        inner_format: {
            rf: gl.RGBA,
            rgf: gl.RGBA,
            rgbf: gl.RGBA,
            rgbaf: gl.RGBA
        },
        type: {
            float: float_type
        },
        linear: supports_linear,
        text: supports_linear ? "SUPPORTS LINEAR" : "DOESNT SUPPORT LINEAR"
    };
}

function loadWebGL2(gl) {
    const exts = {
        color_float: gl.getExtension('EXT_color_buffer_float'),
        texture_float: true,
        texture_float_linear: gl.getExtension('OES_texture_float_linear'),
        float_blend: gl.getExtension('EXT_float_blend')
    };

    const supports_linear = getFloatTypeBuilder(gl, exts, gl.RGBA, gl.RGBA32F, gl.FLOAT, gl.HALF_FLOAT)// + ` - linear: ${gl.getExtension('OES_texture_float_linear')}`;
    const float_type = gl.FLOAT;

    return {
        version: 2,
        exts: exts,
        gl: gl,
        float32Support: (float_type === gl.FLOAT),
        float16Support: (float_type === gl.FLOAT || float_type === gl.HALF_FLOAT),
        format: {
            r: gl.RED,
            rg: gl.RG,
            rgb: gl.RGB,
            rgba: gl.RGBA
        },
        inner_format: {
            rf: (float_type === gl.FLOAT) ? gl.R32F : gl.R16F,
            rgf: (float_type === gl.FLOAT) ? gl.RG32F : gl.RG16F,
            rgbf: (float_type === gl.FLOAT) ? gl.RGB32F : gl.RGB16F,
            rgbaf: (float_type === gl.FLOAT) ? gl.RGBA32F : gl.RGBA16F
        },
        type: {
            float: float_type
        },
        linear: supports_linear,
        text: supports_linear ? "SUPPORTS LINEAR" : "DOESNT SUPPORT LINEAR"
    };
}

function loadGL(canvas) {
    let gl = canvas.getContext("webgl2", { antialias: false });

    if (!gl) {
        gl = canvas.getContext("webgl", { antialias: false });

        if (!gl) {
            return null;
        } else {
            return loadWebGL1(gl);
        }
    } else {
        return loadWebGL2(gl);
    }
}

export default loadGL;