import React from "react";

class SeeMore extends React.Component {
    constructor(props) {
        super(props)

        this.state = { isToggleOn: false }
        this.toggleChildren = this.toggleChildren.bind(this)
    }

    render() {
        if (!this.state.isToggleOn) {
            return (<p className="italic text-sm text-gray-500 hover:cursor-pointer" onClick={this.toggleChildren}>See more</p>)
        } else {
            return (
                <div>
                    {this.props.children.slice(1)}
                    <p className="italic text-sm text-gray-500 hover:cursor-pointer" onClick={this.toggleChildren}>Hide</p>
                </div>
            )
        }
    }

    toggleChildren(event) {
        this.setState(prevState => ({
            isToggleOn: !prevState.isToggleOn
        }))
    }
}

export default function ResumeDescription(props) {
    if (!props.children) {
        return <></>
    }

    if (!Array.isArray(props.children)) {
        return (
            <div className='border-l-2 border-gray-200 pl-2'>
                {props.children}
            </div>
        );
    }

    return (
        <div className='border-l-2 border-gray-200 pl-2'>
            {props.children[0]}
            <SeeMore>
                {props.children}
            </SeeMore>
        </div>
    )

}