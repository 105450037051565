import { loadTexture, loadTextureData } from "./texture";
import Quad from "./quad";
import { initShaderProgram } from "./shader";
import loadGL from "./support";

import * as shaders_linear from './shaders/shaders_linear'
import * as shaders_nonlinear from './shaders/shaders_non_linear'


let SCREEN_WIDTH = 1024;
let SCREEN_HEIGHT = 1024;
const COLOR_WIDTH = 2048;
const COLOR_HEIGHT = 2048;
const SIM_WIDTH = 256;
const SIM_HEIGHT = 256;

function HSVtoRGB(h, s, v) {
    var r, g, b, i, f, p, q, t;
    if (arguments.length === 1) {
        s = h.s
        v = h.v
        h = h.h;
    }
    i = Math.floor(h * 6);
    f = h * 6 - i;
    p = v * (1 - s);
    q = v * (1 - f * s);
    t = v * (1 - (1 - f) * s);
    switch (i % 6) {
        case 0: r = v; g = t; b = p; break;
        case 1: r = q; g = v; b = p; break;
        case 2: r = p; g = v; b = t; break;
        case 3: r = p; g = q; b = v; break;
        case 4: r = t; g = p; b = v; break;
        case 5: r = v; g = p; b = q; break;
        default: break;
    }

    return {
        r: r,
        g: g,
        b: b
    };
}

function simulationReset(gl, framebuffers) {

    Object.keys(framebuffers).forEach((key) => {
        // console.log(key);
        gl.viewport(framebuffers[key].viewport[0], framebuffers[key].viewport[1], framebuffers[key].viewport[2], framebuffers[key].viewport[3]);
        gl.bindFramebuffer(gl.FRAMEBUFFER, framebuffers[key].fb);
        gl.clearColor(0.0, 0.0, 0.0, 0.0);
        gl.clear(gl.COLOR_BUFFER_BIT);
    });

    gl.bindFramebuffer(gl.FRAMEBUFFER, null);
}

function drawScene(gl, quad, shaders, textures, framebuffers) {
    gl.viewport(0, 0, SCREEN_WIDTH, SCREEN_HEIGHT);
    gl.clearColor(0.0, 0.0, 0.0, 1.0);  // Clear to black, fully opaque

    // Clear the canvas before we start drawing on it.
    gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT);


    // Set the shader uniforms
    quad.bind(gl);
    gl.useProgram(shaders.render);

    gl.activeTexture(gl.TEXTURE0);
    gl.bindTexture(gl.TEXTURE_2D, textures.color1);

    gl.uniform1i(gl.getUniformLocation(shaders.render, "tex"), 0);

    gl.drawElements(gl.TRIANGLES, 6, gl.UNSIGNED_SHORT, 0);

    gl.bindBuffer(gl.ARRAY_BUFFER, null);
    gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, null);
}

let counter = 0;
class Framebuffer {

    constructor(fb, viewport) {
        this.id = counter++;
        this.fb = fb;
        this.viewport = viewport;
    }

}

function loadFramebuffer(gl, texture, viewport) {
    // Create and bind the framebuffer
    const fb = gl.createFramebuffer();
    gl.bindFramebuffer(gl.FRAMEBUFFER, fb);

    gl.framebufferTexture2D(gl.FRAMEBUFFER, gl.COLOR_ATTACHMENT0, gl.TEXTURE_2D, texture, 0);

    gl.bindFramebuffer(gl.FRAMEBUFFER, null);

    return new Framebuffer(fb, viewport);
}

function runStep(gl, context, quad, shader, framebuffer, textures) {
    gl.bindFramebuffer(gl.FRAMEBUFFER, framebuffer.fb);

    // Set the shader uniforms
    quad.bind(gl);
    gl.useProgram(shader);

    textures.forEach((texture, i) => {
        gl.activeTexture(gl[`TEXTURE${i}`]);
        gl.bindTexture(gl.TEXTURE_2D, texture.id);
        gl.uniform1i(gl.getUniformLocation(shader, texture.name), i);
    });

    gl.uniform1f(gl.getUniformLocation(shader, "timestep"), context.timestep);
    gl.uniform1f(gl.getUniformLocation(shader, "viscosity"), context.viscosity);
    gl.uniform1f(gl.getUniformLocation(shader, "force_factor"), context.force_factor);
    gl.uniform1f(gl.getUniformLocation(shader, "distance_factor"), context.distance_factor);
    gl.uniform2i(gl.getUniformLocation(shader, "size"), SIM_WIDTH, SIM_HEIGHT);
    gl.uniform2i(gl.getUniformLocation(shader, "size_color"), COLOR_WIDTH, COLOR_HEIGHT);
    gl.uniform2i(gl.getUniformLocation(shader, "size_screen"), SCREEN_WIDTH, SCREEN_HEIGHT);

    gl.uniform3f(gl.getUniformLocation(shader, "background_color"), context.background_color[0], context.background_color[1], context.background_color[2]);

    if (context.new_color) {
        gl.uniform3f(gl.getUniformLocation(shader, "new_color"), context.new_color[0], context.new_color[1], context.new_color[2]);

        if (context.click_pos) {
            gl.uniform2i(gl.getUniformLocation(shader, "click_pos"), context.click_pos[0], context.click_pos[1]);
        }
    } else {
        if (context.click_pos) {
            gl.uniform2i(gl.getUniformLocation(shader, "click_pos"), context.click_pos[0], context.click_pos[1]);
        }
    }

    gl.uniform1f(gl.getUniformLocation(shader, "dx_sim"), SCREEN_WIDTH / SIM_WIDTH);
    gl.uniform1f(gl.getUniformLocation(shader, "dy_sim"), SCREEN_HEIGHT / SIM_HEIGHT);
    gl.uniform1f(gl.getUniformLocation(shader, "dx_color"), SCREEN_WIDTH / COLOR_WIDTH);
    gl.uniform1f(gl.getUniformLocation(shader, "dy_color"), SCREEN_HEIGHT / COLOR_HEIGHT);

    if (context.force_dir) {
        gl.uniform2f(gl.getUniformLocation(shader, "force_dir"), context.force_dir.x, context.force_dir.y);
    }

    gl.drawElements(gl.TRIANGLES, 6, gl.UNSIGNED_SHORT, 0);

    gl.bindBuffer(gl.ARRAY_BUFFER, null);
    gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, null);
    // gl.bindVertexArray(null);
}



function main() {
    const info = document.querySelector("#info-section > span");
    const canvas = document.querySelector("#glCanvas");
    const canvas_holder = document.querySelector(".canvas-holder");

    SCREEN_WIDTH = Math.ceil(canvas_holder.clientWidth) - 200;
    canvas.width = SCREEN_WIDTH;

    const document_height = document.body.scrollHeight;
    const window_height = window.innerHeight;

    if (document_height !== window_height) {
        SCREEN_HEIGHT = Math.ceil(canvas_holder.clientHeight);
        SCREEN_HEIGHT += (window_height - document_height);
        SCREEN_HEIGHT = Math.max(SCREEN_HEIGHT, 300);
        canvas.height = SCREEN_HEIGHT;
    }

    document.resize_observer = new ResizeObserver(() => {
        SCREEN_WIDTH = Math.ceil(canvas_holder.clientWidth);
        canvas.width = SCREEN_WIDTH;

        const document_height = document.body.scrollHeight;
        const window_height = window.innerHeight;

        if (document_height !== window_height) {
            SCREEN_HEIGHT = Math.ceil(canvas_holder.clientHeight);
            SCREEN_HEIGHT += (window_height - document_height);
            SCREEN_HEIGHT = Math.max(SCREEN_HEIGHT, 300);
            canvas.height = SCREEN_HEIGHT;
        }

        simulationReset(gl, framebuffers);
    })
    document.resize_observer.observe(canvas_holder)

    let click_pos;
    let force_dir = {
        x: 0,
        y: 0
    };
    let rec_force_dir = {
        x: 0,
        y: 0
    };

    let background_color = [0, 0, 0];
    let speed = 1.0;

    // canvas.addEventListener("touch", (e) => {
    //     const x = e.clientX - canvas.getBoundingClientRect().left;
    //     const y = SCREEN_HEIGHT - (e.clientY - canvas.getBoundingClientRect().top) - 1;

    //     click_pos = [x / (SCREEN_WIDTH - 1), y / (SCREEN_HEIGHT - 1)];
    // });

    // Initialisation du contexte WebGL
    let loaded_func = loadGL(canvas);

    const version = loaded_func.version;
    const gl = loaded_func.gl;
    const inner_formats = loaded_func.inner_format;
    const formats = loaded_func.format;
    const types = loaded_func.type;

    const refresh = (dt) => {
        info.innerHTML =
            `
        Version: ${version}<br>
        Renderer Size: ${SCREEN_WIDTH}x${SCREEN_HEIGHT}<br>
        Delta time: ${dt}ms
        `;
    };

    // const source = document.querySelector('#color-picker');
    // let picker = new CP(source);
    // picker.on('change', function(r, g, b, a) {
    //     this.source.style.backgroundColor = 'rgb(' + r + ', ' + g + ', ' + b + ')';
    //     background_color = [r / 255, g / 255, b / 255];
    // });

    document.querySelector('#speed-range').addEventListener('input', function (e) {
        speed = this.value / 100.0;
    }, true);

    // Continuer seulement si WebGL est disponible et fonctionnel
    if (!gl) {
        alert("Impossible d'initialiser WebGL. Votre navigateur ou votre machine peut ne pas le supporter.");
        return;
    }

    if (types.float === null) {
        alert("No float type supported");
        return;
    }

    // alert('Version: ' + version);

    const quad = new Quad(gl);

    let shader_source;
    if (loaded_func.linear) {
        shader_source = shaders_linear;
        // shader_source = shaders_nonlinear;
    } else {
        // shader_source = shaders_linear;
        shader_source = shaders_nonlinear;
    }

    const render_shader = initShaderProgram(gl, shader_source.vsRender, shader_source.fsRender);
    const first_color_shader = initShaderProgram(gl, shader_source.vsRender, shader_source.fsFirstColor);
    const set_color_shader = initShaderProgram(gl, shader_source.vsRender, shader_source.fsSetColor);
    const advect_boundary_shader = initShaderProgram(gl, shader_source.vsRender, shader_source.fsAdvectBoundary);
    const advect_color_shader = initShaderProgram(gl, shader_source.vsRender, shader_source.fsAdvectColor);
    const advect_velocity_shader = initShaderProgram(gl, shader_source.vsRender, shader_source.fsAdvectVelocity);
    const advect_pressure_shader = initShaderProgram(gl, shader_source.vsRender, shader_source.fsAdvectPressure);
    const diffuse_shader = initShaderProgram(gl, shader_source.vsRender, shader_source.fsDiffuse);
    const apply_force_shader = initShaderProgram(gl, shader_source.vsRender, shader_source.fsApplyForce);
    const divergence_shader = initShaderProgram(gl, shader_source.vsRender, shader_source.fsDivergence);
    const pressure_boundary_shader = initShaderProgram(gl, shader_source.vsRender, shader_source.fsPressureBoundary);
    const pressure_solve_shader = initShaderProgram(gl, shader_source.vsRender, shader_source.fsPressureSolve);
    const gradient_sub_shader = initShaderProgram(gl, shader_source.vsRender, shader_source.fsGradientSub);


    // inner_formats.rgbaf = gl.RGBA16F; 
    // inner_formats.rgf = gl.RG16F; 
    // inner_formats.rf = gl.R16F; 
    // formats.rgba = gl.RGBA;
    // formats.rg = gl.RG;
    // formats.r = gl.RED;
    // types.float = gl.HALF_FLOAT;

    // const color1 = loadTextureData(gl, SCREEN_WIDTH, SCREEN_HEIGHT, gl.RGBA, gl.RGBA, hf_ext.HALF_FLOAT_OES);
    const color1 = loadTextureData(gl, COLOR_WIDTH, COLOR_HEIGHT, inner_formats.rgbaf, formats.rgba, types.float);
    const color2 = loadTextureData(gl, COLOR_WIDTH, COLOR_HEIGHT, inner_formats.rgbaf, formats.rgba, types.float);
    const velocity1 = loadTextureData(gl, SIM_WIDTH, SIM_HEIGHT, inner_formats.rgf, formats.rg, types.float);
    const velocity2 = loadTextureData(gl, SIM_WIDTH, SIM_HEIGHT, inner_formats.rgf, formats.rg, types.float);
    const pressure1 = loadTexture(gl, SIM_WIDTH, SIM_HEIGHT, inner_formats.rf, formats.r, types.float);
    const pressure2 = loadTexture(gl, SIM_WIDTH, SIM_HEIGHT, inner_formats.rf, formats.r, types.float);
    const divergence = loadTexture(gl, SIM_WIDTH, SIM_HEIGHT, inner_formats.rf, formats.r, types.float);

    const color1_fb = loadFramebuffer(gl, color1, [0, 0, COLOR_WIDTH, COLOR_HEIGHT]);
    const color2_fb = loadFramebuffer(gl, color2, [0, 0, COLOR_WIDTH, COLOR_HEIGHT]);
    const velocity1_fb = loadFramebuffer(gl, velocity1, [0, 0, SIM_WIDTH, SIM_HEIGHT]);
    const velocity2_fb = loadFramebuffer(gl, velocity2, [0, 0, SIM_WIDTH, SIM_HEIGHT]);
    const pressure1_fb = loadFramebuffer(gl, pressure1, [0, 0, SIM_WIDTH, SIM_HEIGHT]);
    const pressure2_fb = loadFramebuffer(gl, pressure2, [0, 0, SIM_WIDTH, SIM_HEIGHT]);
    const divergence_fb = loadFramebuffer(gl, divergence, [0, 0, SIM_WIDTH, SIM_HEIGHT]);

    let then = 0.0;

    const textures = {
        color1: color1,
        color2: color2,
        velocity1: velocity1,
        velocity2: velocity2,
        pressure1: pressure1,
        pressure2: pressure2,
        divergence: divergence
    };

    const framebuffers = {
        color1: color1_fb,
        color2: color2_fb,
        velocity1: velocity1_fb,
        velocity2: velocity2_fb,
        pressure1: pressure1_fb,
        pressure2: pressure2_fb,
        divergence: divergence_fb
    };

    const shaders = {
        render: render_shader,
        first_color: first_color_shader,
        set_color: set_color_shader,
        advect_boundary: advect_boundary_shader,
        advect_color: advect_color_shader,
        advect_velocity: advect_velocity_shader,
        advect_pressure: advect_pressure_shader,
        diffuse: diffuse_shader,
        apply_force: apply_force_shader,
        divergence: divergence_shader,
        pressure_boundary: pressure_boundary_shader,
        pressure_solve: pressure_solve_shader,
        gradient_sub: gradient_sub_shader
    };

    const context = {
        timestep: 0.003,
        viscosity: 0.001,
        background_color: [0, 0, 0],
        force_factor: SCREEN_HEIGHT / 40.0,
        distance_factor: SCREEN_HEIGHT * SCREEN_HEIGHT / 500.0
    };

    let old_click_pos;

    let is_button_down = false;

    let splat_color;

    const mouse_down_func = (e) => {
        is_button_down = true;

        const x = e.clientX - canvas.getBoundingClientRect().left;
        const y = SCREEN_HEIGHT - (e.clientY - canvas.getBoundingClientRect().top) - 1;

        old_click_pos = [x, y];

        splat_color = HSVtoRGB(Math.random(), 1, 1);
        splat_color = [splat_color.r, splat_color.g, splat_color.b];
    };

    const touch_down_func = (e) => {
        is_button_down = true;

        const x = e.changedTouches[0].clientX - canvas.getBoundingClientRect().left;
        const y = SCREEN_HEIGHT - (e.changedTouches[0].clientY - canvas.getBoundingClientRect().top) - 1;

        old_click_pos = [x, y];

        splat_color = HSVtoRGB(Math.random(), 1, 1);
        splat_color = [splat_color.r, splat_color.g, splat_color.b];
    };

    const up_func = (e) => {
        is_button_down = false;
        old_click_pos = null;
        click_pos = null;
    };

    const mouse_move_func = (e) => {
        if (!is_button_down) {
            return;
        }


        const x = e.clientX - canvas.getBoundingClientRect().left;
        const y = SCREEN_HEIGHT - (e.clientY - canvas.getBoundingClientRect().top) - 1;

        click_pos = [x, y];


        if (old_click_pos) {
            force_dir.x += click_pos[0] - old_click_pos[0];
            force_dir.y += click_pos[1] - old_click_pos[1];
        }

        old_click_pos = click_pos;
    };

    const touch_move_func = (e) => {
        if (!is_button_down) {
            return;
        }


        const x = e.changedTouches[0].clientX - canvas.getBoundingClientRect().left;
        const y = SCREEN_HEIGHT - (e.changedTouches[0].clientY - canvas.getBoundingClientRect().top) - 1;

        click_pos = [x, y];

        if (old_click_pos) {
            force_dir.x += click_pos[0] - old_click_pos[0];
            force_dir.y += click_pos[1] - old_click_pos[1];
        }
        console.log([force_dir.x, force_dir.y, click_pos.x, click_pos.y]);

        old_click_pos = click_pos;

        e.preventDefault();
    };

    canvas.addEventListener("mousedown", mouse_down_func, false);
    canvas.addEventListener("touchstart", touch_down_func, false);

    window.addEventListener("mouseup", up_func, false);
    window.addEventListener("touchend", up_func, false);

    canvas.addEventListener("mousemove", mouse_move_func, false);
    canvas.addEventListener("touchmove", touch_move_func, false);

    runStep(gl, context, quad, shaders.first_color, framebuffers.color1, []);

    const step_func = (deltaTime) => {
        context.background_color = background_color;

        if (deltaTime > 100.0) {
            context.timestep = speed * 0.1;
        } else {
            context.timestep = speed * deltaTime / 1000.0;
        }

        if (context.timestep < 0.00001) {
            return;
        }

        gl.viewport(0, 0, SIM_WIDTH, SIM_HEIGHT);

        runStep(gl, context, quad, shaders.advect_boundary, framebuffers.velocity2, [
            { id: textures.velocity1, name: "velocity_sampler" }
        ]);

        [textures.velocity1, textures.velocity2] = [textures.velocity2, textures.velocity1];
        [framebuffers.velocity1, framebuffers.velocity2] = [framebuffers.velocity2, framebuffers.velocity1];

        gl.viewport(0, 0, COLOR_WIDTH, COLOR_HEIGHT);

        if (is_button_down && click_pos) {
            context.click_pos = click_pos;
            context.force_dir = force_dir;

            // context.new_color = [1.0, 0.0, 0.0];
            context.new_color = splat_color;

            runStep(gl, context, quad, shaders.set_color, framebuffers.color2, [
                { id: textures.color1, name: "color_sampler" }
            ]);

            [textures.color1, textures.color2] = [textures.color2, textures.color1];
            [framebuffers.color1, framebuffers.color2] = [framebuffers.color2, framebuffers.color1];

            delete context.new_color;
        }

        runStep(gl, context, quad, shaders.advect_color, framebuffers.color2, [
            { id: textures.color1, name: "color_sampler" },
            { id: textures.velocity1, name: "velocity_sampler" },
        ]);

        [textures.color1, textures.color2] = [textures.color2, textures.color1];
        [framebuffers.color1, framebuffers.color2] = [framebuffers.color2, framebuffers.color1];

        gl.viewport(0, 0, SIM_WIDTH, SIM_HEIGHT);

        runStep(gl, context, quad, shaders.advect_pressure, framebuffers.pressure2, [
            { id: textures.velocity1, name: "velocity_sampler" },
            { id: textures.pressure1, name: "pressure_sampler" }
        ]);

        [textures.pressure1, textures.pressure2] = [textures.pressure2, textures.pressure1];
        [framebuffers.pressure1, framebuffers.pressure2] = [framebuffers.pressure2, framebuffers.pressure1];

        runStep(gl, context, quad, shaders.advect_velocity, framebuffers.velocity2, [
            { id: textures.velocity1, name: "velocity_sampler" }
        ]);

        [textures.velocity1, textures.velocity2] = [textures.velocity2, textures.velocity1];
        [framebuffers.velocity1, framebuffers.velocity2] = [framebuffers.velocity2, framebuffers.velocity1];

        for (let i = 0; i < 30; ++i) {
            runStep(gl, context, quad, shaders.diffuse, framebuffers.velocity2, [
                { id: textures.velocity1, name: "velocity_sampler" }
            ]);

            [textures.velocity1, textures.velocity2] = [textures.velocity2, textures.velocity1];
            [framebuffers.velocity1, framebuffers.velocity2] = [framebuffers.velocity2, framebuffers.velocity1];
        }

        if (is_button_down) {
            context.click_pos = click_pos;
            context.force_dir = force_dir;

            runStep(gl, context, quad, shaders.apply_force, framebuffers.velocity2, [
                { id: textures.velocity1, name: "velocity_sampler" }
            ]);

            [textures.velocity1, textures.velocity2] = [textures.velocity2, textures.velocity1];
            [framebuffers.velocity1, framebuffers.velocity2] = [framebuffers.velocity2, framebuffers.velocity1];

            delete context.click_pos;
            delete context.force_dir;

            rec_force_dir.x = force_dir.x;
            rec_force_dir.y = force_dir.y;

            force_dir.x = 0;
            force_dir.y = 0;
        }

        runStep(gl, context, quad, shaders.divergence, framebuffers.divergence, [
            { id: textures.velocity1, name: "velocity_sampler" }
        ]);

        runStep(gl, context, quad, shaders.pressure_boundary, framebuffers.pressure2, [
            { id: textures.pressure1, name: "pressure_sampler" }
        ]);

        [textures.pressure1, textures.pressure2] = [textures.pressure2, textures.pressure1];
        [framebuffers.pressure1, framebuffers.pressure2] = [framebuffers.pressure2, framebuffers.pressure1];

        for (let i = 0; i < 70; ++i) {
            runStep(gl, context, quad, shaders.pressure_solve, framebuffers.pressure2, [
                { id: textures.pressure1, name: "pressure_sampler" },
                { id: textures.divergence, name: "divergence_sampler" }
            ]);

            [textures.pressure1, textures.pressure2] = [textures.pressure2, textures.pressure1];
            [framebuffers.pressure1, framebuffers.pressure2] = [framebuffers.pressure2, framebuffers.pressure1];
        }

        runStep(gl, context, quad, shaders.advect_boundary, framebuffers.velocity2, [
            { id: textures.velocity1, name: "velocity_sampler" }
        ]);

        [textures.velocity1, textures.velocity2] = [textures.velocity2, textures.velocity1];
        [framebuffers.velocity1, framebuffers.velocity2] = [framebuffers.velocity2, framebuffers.velocity1];

        runStep(gl, context, quad, shaders.gradient_sub, framebuffers.velocity2, [
            { id: textures.pressure1, name: "pressure_sampler" },
            { id: textures.velocity1, name: "velocity_sampler" }
        ]);

        [textures.velocity1, textures.velocity2] = [textures.velocity2, textures.velocity1];
        [framebuffers.velocity1, framebuffers.velocity2] = [framebuffers.velocity2, framebuffers.velocity1];


        gl.bindFramebuffer(gl.FRAMEBUFFER, null);
    };

    let deltaTime;
    // Draw the scene repeatedly
    function render(now) {
        deltaTime = now - then;
        then = now;


        step_func(deltaTime);

        drawScene(gl, quad, shaders, textures, framebuffers);

        setTimeout(() => {
            requestAnimationFrame(render);
        }, 0)
    }

    setInterval(() => {
        refresh(Math.round(deltaTime * 100) / 100);
    }, 100)

    requestAnimationFrame(render);
}

function supportedExtensions() {
    return document.createElement("canvas").getContext("webgl").getSupportedExtensions();
}

export { main as render_init, supportedExtensions };