import React from "react";

export default function Navbar(props) {
    const [navbarOpen, setNavbarOpen] = React.useState(false);
    return (
        <>
            <nav className="relative flex flex-wrap items-center justify-between md:px-2 py-3 mb-3">
                <div className="w-full md:container md:px-4 mx-auto flex flex-wrap items-center justify-between">
                    <div className="w-full relative flex justify-between md:w-auto md:static md:block md:justify-start  pb-2 px-4 md:px-0">
                        <a
                            className="text-gray-900 no-underline hover:no-underline text-xl "
                            href="/"
                        >
                            Clément Jacquet
                        </a>
                        <button
                            className="flex items-center px-3 py-2 border md:hidden rounded text-gray-500 border-gray-600 hover:text-gray-900 hover:border-gray-900 appearance-none focus:outline-none"
                            type="button"
                            onClick={() => setNavbarOpen(!navbarOpen)}
                        >
                            <svg className="fill-current h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                <title>Menu</title>
                                <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
                            </svg>
                        </button>
                    </div>
                    <div
                        className={
                            "md:flex flex-grow items-center border md:border-0 border-gray-300 bg-gray-200 md:bg-inherit"  +
                            (navbarOpen ? " flex" : " hidden")
                        }
                        id="example-navbar-danger"
                    >
                        <ul className="flex flex-col md:flex-row list-none md:ml-auto">
                            <li className="nav-item">
                                <a className={"inline-block py-2 px-4 text-gray-600 no-underline hover:text-gray-900 hover:text-underline" + (props.selected === "home" ? " font-bold" : "")} href="/">Home</a>
                            </li>
                            <li className="nav-item">
                                <a className={"inline-block py-2 px-4 text-gray-600 no-underline hover:text-gray-900 hover:text-underline" + (props.selected === "projects" ? " font-bold" : "")} href="/projects">Projects</a>
                            </li>
                            <li className="nav-item">
                                <a className={"inline-block py-2 px-4 text-gray-600 no-underline hover:text-gray-900 hover:text-underline" + (props.selected === "blog" ? " font-bold" : "")} href="/blog">Blog</a>
                            </li>
                            <li className="nav-item">
                                <a className={"inline-block py-2 px-4 text-gray-600 no-underline hover:text-gray-900 hover:text-underline" + (props.selected === "resume" ? " font-bold" : "")} href="/resume">About me</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </>
    );
}