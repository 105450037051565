import React from 'react';
import Navbar from '../navbar/navbar';
import Footer from '../footer/footer';
import { TypeAnimation } from 'react-type-animation';
import { Carousel } from 'react-responsive-carousel';

import fr_flag from './fr-flag.png';
import jp_flag from './jp-flag.png';
import gb_flag from './gb-flag.png';

import "./carousel.css"; // requires a loader
import './Main.css';

class Resume extends React.Component {
  componentDidMount() {
    document.title = 'CJ - Home'
  }

  render() {
    return (
      <div className='Resume bg-gray-100 font-sans min-h-screen'>
        <Navbar selected="home" />

        <div className="container w-full md:max-w-6xl mx-auto px-10 my-20">
          <h1 className='text-5xl text-center pt-5 pb-20'>
            {/* Welcome to my personal website
             */}
            <TypeAnimation
              sequence={[
                'Welcome',
                1000,
                'Welcome to my personal website'
              ]}
              wrapper="div"
              cursor={false}
              repeat={false}
            />
          </h1>

          <p className='text-center mb-16 text-lg'>
            My portfolio and (future?) devblog. Read about my dev projects, my resume, and also articles on topics I am passionate about here!
          </p>

          <div className='mb-8'>
            <h2 className='mb-12 text-center text-4xl'><a className="no-underline hover:underline " href='/projects'>Projects</a></h2>

            <p className='mb-4 text-center text-lg'>
              I write code both professionally and on my free time, mostly related to computer graphics and with C++. 
              Some part of it is public, for now mainly prototypes or University projects, however I am motivated to start
              sharing more in the future.
            </p>
            <Carousel>
                <div>
                    <img src="fluid-sim-preview.jpg" />
                    <p className="legend">Real-time interactive 2D fluid solver</p>
                </div>
                <div>
                    <img src="raytracing-thumb.jpg" />
                    <p className="legend">Real-time ray tracing with the Vulkan ray tracing extension</p>
                </div>
            </Carousel>
            <p className='mt-2 text-center text-lg'>
              Please check my already available open-source code in the link below:
            </p>
            <p className='text-center text-lg'>
              <a className="text-sky-500 no-underline hover:underline " href='/projects'>See my projects here</a>
            </p>
          </div>

          <div>
            <h2 className='mt-14 mb-12 text-center text-4xl'><a className="no-underline hover:underline " href='/resume'>About me</a></h2>


            <div className='grid mb-4'>
              <div>
                <div className='text-center'>
                  <div className='resume-avatar'>
                    <img alt="" src={"https://avatars.githubusercontent.com/u/9611659?v=4"} />
                  </div>
                  <h2 className="text-xl text-gray-800 mt-4">Clément Jacquet</h2>
                  <span className='text-sm text-gray-700'>Osaka, Japan</span><br />
                  <p className='text-gray-900 mt-2 max-w-md mx-auto'>
                    French system engineer specialized in graphics and software design. Developer for nearly 10 years, I enjoy computer graphics and creating high-performance software, mostly in C++.
                  </p>
                  <p>
                  <a className="text-sky-500 no-underline hover:underline " href='/resume'>See my full resume here</a>
                  </p>
                  <div className='mt-4 mb-4 max-w-xs mx-auto'>
                    <hr className="border-b-1 border-gray-300" />
                  </div>
                  <div className='text-left max-w-xs mx-auto mt-3'>
                    <span className=''><img alt="" className='resume-flag' src={fr_flag}></img> Native speaker</span><br />
                    <span className=''><img alt="" className='resume-flag' src={gb_flag}></img> Professional working proficiency</span><br />
                    <span className=''><img alt="" className='resume-flag' src={jp_flag}></img> Limited working proficiency (JLPT N2)</span>
                  </div>
                  <div className='mt-4 mb-4 max-w-xs mx-auto'>
                    <hr className="border-b-1 border-gray-300" />
                  </div>
                  <div className='pb-6'>
                    <div>
                      <span className=''>Very familiar</span>
                    </div>
                    <div>
                      <span className='px-2 text-gray-700'>C++</span>
                      <span className='px-2 text-gray-700'>OpenGL</span>
                      <span className='px-2 text-gray-700'>Python</span>
                      <span className='px-2 text-gray-700'>Javascript</span>
                    </div>
                    <div className='mt-2'>
                      <span>From time to time</span>
                    </div>
                    <div>
                      <span className='px-2 text-gray-700'>C</span>
                      <span className='px-2 text-gray-700'>Vulkan</span>
                      <span className='px-2 text-gray-700'>C#</span>
                      <span className='px-2 text-gray-700'>Java</span>
                      <span className='px-2 text-gray-700'>Rust</span>
                      <span className='px-2 text-gray-700'>Ruby</span><br />
                      <span className='px-2 text-gray-700'>Typescript</span>
                      <span className='px-2 text-gray-700'>AngularJS</span>
                      <span className='px-2 text-gray-700'>ReactJS</span>
                    </div>
                    <div className='mt-2'>
                      <span>At rare occasions</span>
                    </div>
                    <div>
                      <span className='px-2 text-gray-700'>Unity</span>
                      <span className='px-2 text-gray-700'>PHP</span>
                      <span className='px-2 text-gray-700'>Java</span>
                      <span className='px-2 text-gray-700'>Rust</span>
                      <span className='px-2 text-gray-700'>Ruby</span>
                    </div>
                  </div>

                </div>

              </div>
            </div>
          </div>
        </div>



        <Footer />
      </div>
    );
  }
}

export default Resume;