import React from 'react';
import { render_init } from './js/script'
import Navbar from '../navbar/navbar';
import Footer from '../footer/footer';

import './css/style.css'

class FluidSimulation extends React.Component {
  componentDidMount() {
    this.render_step = render_init()

    // this.render_step()
  }

  onSpeedChange() {
    console.log('HELLO')
  }

  render() {
    return (
      <div>
        <Navbar selected="projects" />

        <div className="w-full">
          <div className='container w-full md:max-w-4xl mx-auto px-10 py-3'>
            <h1><a className='text-sky-500 no-underline hover:underline' href="/projects">Return to the projects page</a></h1>
            <p>Click on/Touch the black area below and slide your cursor/finger to color and apply a force to the fluid.</p>
          </div>
          <div className='canvas-holder'>
            <canvas id="glCanvas">

            </canvas>
            <div id="control-section">
              <p><label>Speed:</label><input className="slider" id="speed-range" type="range" min="0" max="100" value="100" onChange={this.onSpeedChange} /></p>
            </div>
            <div id="info-section">
              <span>This is a test</span>

            </div>

          </div>
        </div>

        <Footer />
      </div>
    )
  }
}

export default FluidSimulation;
