import React from "react";

export default function Footer() {
  return (
    <footer className="bg-white border-t border-gray-400 shadow flex-none">
      <div className="container max-w-4xl mx-auto flex py-2">

        <div className="w-full mx-auto flex flex-wrap">
          <div className="flex w-full md:w-1/2">
            <div className="px-12">
              <h3 className="font-bold text-gray-900 pt-2">Social</h3>
              <div className="py-1">
                {/* <ul className="list-reset items-center text-sm pt-3"> */}
                {/* <li> */}
                <a className="inline-block text-gray-600 no-underline hover:text-gray-900 hover:text-underline py-1" href="https://github.com/clejacquet">Github</a>
                {/* </li> */}
                <span> - </span>
                {/* <li> */}
                <a className="inline-block text-gray-600 no-underline hover:text-gray-900 hover:text-underline py-1" href="https://www.linkedin.com/in/clejacquet/">LinkedIn</a>
                {/* </li> */}
                {/* </ul> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}