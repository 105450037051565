import React from 'react';
import Navbar from '../navbar/navbar';
import Footer from '../footer/footer';

import './Blog.css';

class Blog extends React.Component {
  componentDidMount() {
    document.title = 'CJ - Blog'
  }

  render() {
    return (
      <div className='bg-gray-100 font-sans flex flex-col min-h-screen'>
        <Navbar selected="blog" />


        <div className="container w-full md:max-w-4xl mx-auto grow flex">
          <div className='m-auto'>
            <h1 className='text-center text-lg text-gray-800 font-medium'>Coming soon...(?)</h1>
            <h2 className='text-center text-gray-800 mt-4'>In the meantime, please check my <a className='font-medium' href='/resume'>resume</a> and <a className='font-medium' href='/projects'>projects</a><br/>
            <a className='font-medium' href='/'>Return to the home page </a>
            </h2>
          </div>
        </div>

        <Footer />
      </div>
    );
  }
}

export default Blog;