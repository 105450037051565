import React from 'react';
import Navbar from '../navbar/navbar';
import Footer from '../footer/footer';


class Projects extends React.Component {
  componentDidMount() {
    document.title = 'CJ - Projects'
  }

  render() {
    return (
      <div className='Projects bg-gray-100 font-sans  flex flex-col min-h-screen'>
        <Navbar selected="projects" />

        <div className="container w-full md:max-w-4xl mx-auto grow flex">
          <div className='m-auto'>
            <div className='mb-8 md:px-20 px-8'>
              <h1 className='text-center text-gray-800 text-4xl py-8 mb-4'>Personal/University Projects</h1>
              <p className="text-gray-800">
                Below are shown some of the projects I have been working on for my University courses or during my free time.
              </p>
              <ul>

                <h2 className='font-medium text-3xl mt-8 mb-5'><a className="no-underline hover:underline" rel="noreferrer" target="_blank" href='https://github.com/clejacquet/fluid-simulation'>2D fluid simulation with OpenGL/WebGL</a></h2>
                <div>
                  <p className='my-4'>
                    A 2D interactive fluid solver, built in C++ with OpenGL for the desktop version, and Javascript with WebGL for the web version.
                  </p>
                  <a  className="text-sky-500 no-underline hover:underline" href='/projects/fluid-simulation'>See the demo</a>
                </div>
                <video loop muted playsInline controls preload="none" poster='fluid-sim-thumb.jpg' className='my-4'>
                  <source src="fluid-sim-2.mp4" type="video/mp4" />
                </video>
                <p className='my-4'>
                  The implementation is based on <a className="font-medium no-underline hover:underline" rel="noreferrer" target="_blank" href='https://developer.nvidia.com/gpugems/gpugems/part-vi-beyond-triangles/chapter-38-fast-fluid-dynamics-simulation-gpu'>this chapter</a> of
                  the "GPU Gems" book from Nvidia. A grid is used to track the fluid color, velocity and pressure across a 2D square domain. This domain has closed boundaries, so that the fluid quantities do not leave the domain of the simulation.

                  By pressing the mouse left button, a force and a random color are applied to the fluid, enabling to interact with its behavior and create visually interesting results.   

                </p>
                <p className='my-4'>
                  The desktop version relies on compute shaders with Image Load/Store operations, while the web version uses ping-pong framebuffers, as compute shaders are not available in most browsers.
                </p>

                <p className='my-4'>
                  In a more or less close future, I am considering adding a 3rd dimension while maintaining a real-time visualization. I have the chance to work at my current job 
                  on a real-time 3D volume renderer which might (soon) be open-sourced, which makes me hopeful that these two projects could connect in some way one day.
                </p>

                <p className='my-4'>
                  Once <span className='font-medium'>WebGPU</span> is released and available in major browsers, the WebGL renderer will surely be replaced by a WebGPU implementation.

                </p>




                <h2 className='font-medium text-3xl mt-8 mb-5'><a className="no-underline hover:underline" rel="noreferrer" target="_blank" href='https://github.com/clejacquet/nv_vulkan_raytrace'>Vulkan ray-tracing extension experiments</a></h2>
                <div>
                  <p className='my-4'>
                    Fork of the Vulkan raytracing tutorial repository provided by Nvidia, available <a className="font-medium no-underline hover:underline" rel="noreferrer" target="_blank" href='https://nvpro-samples.github.io/vk_raytracing_tutorial_KHR/'>here</a>.
                    A real-time implementation of the book "Ray Tracing in One Weekend" is provided in the folder rt_weekend.
                  </p>
                  <p className='my-4'>
                    A GPU supporting the Vulkan Ray Tracing extensions is required to run the demos.
                  </p>
                </div>
                <video loop muted playsInline controls preload="none" poster='raytracing-thumb.jpg' className='my-4'>
                  <source src="raytracing-1.mp4" type="video/mp4" />
                </video>

                <h2 className='font-medium text-3xl mt-8 mb-5'><a className="no-underline hover:underline" rel="noreferrer" target="_blank" href='https://github.com/clejacquet/c2asmjs'>C to ASMJS compiler</a></h2>
                <div>
                  <p className='my-4'>
                    A Ruby compiler that translates (a subset of) C code into LLVM intermediate language (IL) code.
                  </p>
                  <p className='my-4'>
                    The IL code is then compiled to ASMJS with Emscripten, which can run on most browsers. This project has been built using Rex & Racc, the Ruby port of the famous Lex & Yacc tools.
                  </p>
                </div>

              </ul>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    );
  }
}

export default Projects;